import React from 'react';
import Allannouncements from './Allannouncements';
import { useLocation } from 'react-router-dom';


const Hrview = ({prop}) => {
    const location = useLocation();
    const stateValue = location.state && location.state['userPermissions'];
    if (stateValue === "hr_permission") {
        console.log('State Value:', stateValue);
    }

    return (
        <div>            
            <Allannouncements dataProp={stateValue} />
        </div>
    );
};

export default Hrview