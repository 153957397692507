import React, { useState, useEffect, useRef } from "react";
// import qimg from "../Ass";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import Announcementslider from "./Announcementslider";
import Announcementslider from "../../Announcementslider";


const quotes = [
  "A diamond is a piece of coal that is stuck to the job. Your most important work is always ahead of you, never behind you.",
  "The difference between ordinary and extraordinary is that little extra.",
  "Don’t try to be the best, try to be a better version than yesterday!",
  "There are no shortcuts to any place worth going.",
  "Believing in yourself is the first secret to success.",
  "Positive attitude will lead you to a positive outcome",
  "The only real limitation on your abilities is the level of your desires. If you want it badly enough, there are no",
  "Work hard in silence, Let your success make noise.",
  "Never give up because great things take time and some effort.",
  "Sooner or later, those who win are those who think they can.",
  "Trust yourself, you know more than you think you do.",
  "I was always looking outside myself for strength and confidence but it comes from within. It is there all the time.",
  "The expert in anything was once a beginner.",
];

const TLSlider = () => {
  const [data, setData] = useState(null);
  console.log("hell")
  const [dailyEntryData, setdailyEntryData] = useState(null);

  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const sliderContainer = useRef(null);

  const [announcements, setAnnouncements] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [latest_news, setLatestNews] = useState([]);

  const [messageShown, setMessageShown] = useState(false);


  const navigate = useNavigate();


  const handleSignOut = () => {
    localStorage.removeItem("access_token");

    navigate('/');
  };

  useEffect(() => {
    async function fetchLeaders() {
      try {
        const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/leaders/');
        if (!response.ok) {
          throw new Error('Failed to fetch leaders');
        }
        const data = await response.json();
        console.log("Response: ", data);
        console.log("Leaders: ", data.leaders);

        setData(data.leaders);

        // setLatestNews(data.leaders.latest_news);
      } catch (error) {
      }
    }

    fetchLeaders();
  }, []);


  useEffect(() => {
    async function fetchLeaders() {
      try {
        if (data && data.length > 0) {
          const currentRecord = data[focusedIndex];
          // console.log("Current Record: ", currentRecord, data[focusedIndex]);
          const response = await fetch(`https://scoreboard.codingindia.co.in/LeadersTable/leaders_id/${currentRecord.id}/`);
          if (!response.ok) {
            throw new Error('Failed to fetch leaders');
          }
          const responseData = await response.json();
          console.log("Person Response: ", responseData);

          setdailyEntryData(responseData.leader.currentVal);
          setTasks(responseData.leader.tasks);
          setLatestNews(responseData.leader.latest_news)
        }
      } catch (error) {
        // console.error(error);
      }
    }

    fetchLeaders();
  }, [focusedIndex, data]);



  useEffect(() => {
    const interval = setInterval(() => {
      if (data) {
        setFocusedIndex((prevIndex) => {
          const currentRecord = data[prevIndex];

          if (prevIndex === data.length - 1) {
            if (latest_news != null) {
              navigate('/celebrationPage')
              console.log("Latest News: ", latest_news.length)
            } else {
              navigate("/records");
            }
          }
          else {
          }

          return (prevIndex + 1) % data.length;
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [data]);

  return (

    <>

      {data && (
        <div class="recentContainer">
          {/* <Announcementslider /> */}
          <div class="row">
            <div class="col-12">
              <div id="carouselExample" class="carousel slide">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="row">
                      <div class="col-7">
                        <div class="row">
                          <div class="col-8" style={{ marginTop: "30px" }}>
                            <h1 class="authorName">
                              {data[focusedIndex].name}
                            </h1>
                            <p class="authorPos">{data[focusedIndex].dept}</p>
                            <Link
                              className="signOutLinkRecord"
                              to={{ pathname: '/' }}
                              onClick={handleSignOut}
                            > <i class="fa-solid fa-right-from-bracket"></i></Link>
                          </div>
                          <div class="col-4" style={{ marginTop: "30px" }}>
                            <div class="targetBox">
                              <h2>{focusedIndex + 1}</h2>
                              <p>Ranking</p>
                            </div>
                          </div>

                          {tasks && tasks.map((task, index) => {
                            const taskItem = dailyEntryData.find(item => item.name === task.id);
                            const percentage = (taskItem?.total_taskinput / task.target) * 100;

                            let backgroundColor;
                            let textColor;
                            
                            if (percentage < 50) {
                              backgroundColor = "red";
                            } else if (percentage < 75) {
                              backgroundColor = "lightyellow";
                            } else if (percentage < 90) {
                              backgroundColor = "#22c55en";
                            } else if (percentage >= 90 && percentage <= 100) {
                              backgroundColor = "#166534";
                            } else if (percentage >= 90 && percentage <= 100) {
                              backgroundColor = "#0284c7";
                            } else {
                              backgroundColor = "#1e293b";
                            }

                            textColor = backgroundColor === "lightyellow" ? "black" : "white";

                            return (
                              <div className="col-4" style={{ marginTop: "30px" }} key={index}>
                                <div
                                  className="turnedUp teamLeaderSlider"
                                  style={{ backgroundColor, color: textColor }}
                                >
                                  <div>
                                    <h3 style={{ color: textColor }}>{task.name}</h3>
                                    <div>
                                      <p style={{ color: `${textColor}!important` }} className="text-center">{taskItem?.total_taskinput}</p>
                                      <p style={{ color: `white !important` }} className="text-center text-white"><span style={{ color: "white" }}>Percentage: {percentage.toFixed(2)}%</span></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                          <div className="col-12" style={{ marginTop: "30px" }}>
                            <div class="qoutesBoxx">
                              {/* <img src={qimg} /> */}
                              <q className="text-3xl">{quotes[focusedIndex]}</q>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-5 userImageDiv"
                        style={{ marginTop: "30px" }}
                      >
                        <img
                          src={`https://scoreboard.codingindia.co.in${data[focusedIndex].image}`}
                          alt=""
                          class="userImage"
                        />

                      </div>
                    </div>
                    <div class="row"></div>
                  </div>
                  <div class="carousel-item"></div>
                  <div class="carousel-item"></div>
                </div>

              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      )}
    </>
  );
};

export default TLSlider;