import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const imageUrl =
  "https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if user is already logged in
  //   const accessToken = localStorage.getItem("access_token");
  //   if (accessToken) {
  //     // User is already logged in, redirect to the desired page
  //     navigate("/records");
  //   }
  // }, []); 

  
  // Create the submit method.
  const submit = async (e) => {
    e.preventDefault();
    const user = {
      username: username,
      password: password,
    };
    // Create the POST requuest
    

    const { data } = await axios.post(
      "https://scoreboard.codingindia.co.in/token/",
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    // Initialize the access & refresh token in localstorage.
    localStorage.clear();
    localStorage.setItem("access_token", data.access);
    localStorage.setItem("refresh_token", data.refresh);
    axios.defaults.headers.common["Authorization"] = `Bearer ${data["access"]}`;
    // window.location.href = '/'
    
    navigate("/records");
    console.log("Token Generated ");
  };

  return (
    <>
      <main class="main-content  mt-0">
        <div
          class="page-header align-items-start min-vh-100"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <span class="mask bg-gradient-dark opacity-6"></span>
          <div class="container my-auto">
            <div class="row">
              <div class="col-lg-4 col-md-8 col-12 mx-auto">
                <div class="card z-index-0 fadeIn3 fadeInBottom">
                  <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div class="bg-gradient-primary shadow-primary border-radius-lg py-3 pe-1">
                      <h4 class="text-white text-center mb-0">
                        Dashboard Login
                      </h4>
                    </div>
                  </div>
                  <div class="card-body">
                    <form role="form" class="text-start" onSubmit={submit}>
                      <div class="input-group input-group-outline my-3">
                        <label class="username" style={{ width: "100%" }}>
                          Username
                          <input
                            type="text"
                            value={username}
                            id="username"
                            class="form-control"
                            style={{ width: "100%" }}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </label>
                      </div>
                      <div class="input-group input-group-outline mb-3">
                        <label class="password" style={{ width: "100%" }}>
                          Password
                        </label>
                        <input
                          type="password"
                          value={password}
                          id="password"
                          class="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div class="text-center">
                        <button
                          type="submit"
                          class="btn bg-gradient-primary w-100 my-4 mb-2"
                        >
                          Sign in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Login;
