import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import './ViewTeamLead.css';
// import Announcementslider from "./Announcementslider";


function ViewTeamLead({ prop }) {
  const location = useLocation();
  const stateValue = location.state && location.state['userPermissions'];
  const [dailyEntryData, setdailyEntryData] = useState(null);
  // console.log("State Value", stateValue);
  const [data, setData] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [isAuth, setIsAuth] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false); // State to store superuser status
  const navigate = useNavigate();
  const [latestNews, setLatestNews] = useState(null)
  const [pipelineandturnedup, setPipelineandturnedup] = useState(null);
  const handleSignOut = () => {

    localStorage.removeItem("access_token");

    navigate('/');
  };


  useEffect(() => {
    async function fetchLeaders() {
      try {
        const response = await fetch('https://scoreboard.codingindia.co.in/LeadersTable/leaders/');
        if (!response.ok) {
          throw new Error('Failed to fetch leaders');
        }
        const data = await response.json();
        console.log("Response: ", data)
        console.log("Leaders: ", data.leaders)

        setData(data.leaders);
        // setLoading(false);
      } catch (error) {
        console.error(error);
        // setLoading(false);
      }
    }

    fetchLeaders();
  }, []);

  // ############################ Iteration ########################################
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(3);
  const totalPages = Math.ceil((data && data.length) / recordsPerPage);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentViewers = data && data.slice(indexOfFirstRecord, indexOfLastRecord);
  const globalRecordCount = (currentPage - 1) * recordsPerPage;

  const paginate = () => {
    // Move to the next page
    setCurrentPage((prevPage) => (prevPage === totalPages ? 1 : prevPage + 1));
    // setCurrentPage((prevPage) => (prevPage === totalPages ? 4 : 4));
  };


  useEffect(() => {
    if (data && data.length > 0) {
      const focusOnRecord = (index) => {
        setFocusedIndex(index);

        const timer = setTimeout(() => {
          const nextIndex = (index + 1) % recordsPerPage;

          // If the next record is on a new page, paginate to the next page
          if (nextIndex === 0) {
            if (currentPage === totalPages) {
              // Navigate to "/slider" page if not a superuser
              if (
                !isSuperuser &&
                !userPermissions.includes("website.see_records") &&
                !userPermissions.includes("website.update_target")
              ) {
                navigate("/tlslider");
              }
            } else {
              paginate(); // Move to the next page
            }
          }

          setFocusedIndex(nextIndex);
        }, 5000);

        return () => clearTimeout(timer);
      };

      // Start focusing on the first record
      focusOnRecord(focusedIndex);

      return () => setFocusedIndex(0); // Reset focusedIndex when the component unmounts
    }
  }, [
    focusedIndex,
    data,
    currentPage,
    totalPages,
    paginate,
    navigate,
    isSuperuser,
  ]);


  // ############################ Iteration ########################################

  return (
    <>
      <section>
        <div class="viewTeamLeadHead w-95 m-auto">
          <div class="teamLeadHead">
            <h3>Team Leader List</h3>
          </div>
        </div>

        <div>
          <div>
            <div>
              <div>


                {/****************************** Viewer Code (Someone) ******************************/}
                <table class="myTable min-w-full bg-white" style={{ width: "95%", margin: "auto" }}>
                  <thead>
                    <tr>
                      <th scope="col" class="rankWidth">
                        Ranks
                      </th>
                      <th scope="col" class="hrWidth">
                        Image
                      </th>
                      <th scope="col" class="nameWidth">
                        Name
                      </th>
                      <th scope="col" class="percentageWidth">
                        Percentage
                        <Link
                          className="signOutLink"
                          to={{ pathname: '/' }}
                          onClick={handleSignOut}
                        >
                          <i class="fa-solid fa-right-from-bracket"></i>
                        </Link>
                      </th>
                      {!isSuperuser &&
                        userPermissions.includes("website.see_records") && (
                          <th className="col">Options</th>
                        )}
                    </tr>
                  </thead>

                  <tbody>
                    {currentViewers &&
                      currentViewers.map((record, index) => (
                        <tr
                          key={index}
                          className={` ${index === focusedIndex ? "actives" : ""
                            }`}
                          style={{
                            height: "10vh!important",
                            position: "relative",
                          }}
                        >
                          <td style={{ textAlign: "center" }} class="py-2 px-4 border-b text-center">
                            <div class="awards">
                              <span className={` ${index === focusedIndex
                                ? "text-4xl font-bold  recordRanking award-content"
                                : "text-xl recordRanking award-content"
                                }`}
                              >{globalRecordCount + index + 1}</span>
                              <span class="image-content"></span>
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }} class="py-2 px-4 border-b text-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={`https://scoreboard.codingindia.co.in${record.image}`}
                                style={{
                                  width: "auto",
                                  height: "25vh",
                                  maxHeight: "30vh",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                className={` ${index === focusedIndex
                                  ? "activeImgBor text-center"
                                  : "myimgborder"
                                  }`}
                                alt=""
                              />
                            </div>
                          </td>
                          <td
                            style={{ textAlign: "center" }}
                            className={` ${index === focusedIndex
                              ? "text-4xl font-bold"
                              : "text-xl py-2 px-4 border-b text-center"
                              }`}
                          >
                            {record.name}
                          </td>
                          <td className="py-2 px-4 border-b text-center progress-bar-container" style={{ width: "50%", height: "20px" }} >
                            <div className={`progress-bar ${record.calculatePercentage < 50 ? "progress-bar-red" : record.calculatePercentage < 80 ? "progress-bar-light-green" : "progress-bar-green"}`} style={{ width: `${record.calculatePercentage}%` }}>
                              {record.calculatePercentage === 50 ? <span style={{ marginLeft: "-15px" }}>
                                {Math.ceil(record.calculatePercentage)}%
                              </span> : Math.ceil(record.calculatePercentage) + "%"}
                            </div>
                          </td>

                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* <Announcementslider /> */}
      </section>

    </>
  );
}
export default ViewTeamLead;
