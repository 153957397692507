// CreateTeamLeaderTargetTarget.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const CreateTeamLeaderTarget = () => {
    const [successMessage, setSuccessMessage] = useState('');
    const [markettingTypes, setMarkettingTypes] = useState([]);

    const [newTeamLeader, setNewTeamLeader] = useState({
        name: '',
        collection: 0,
        confirmation: 0,
        closure: 0,
    });

    // Function to handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewTeamLeader((prevTeamLeader) => ({
            ...prevTeamLeader,
            [name]: value,
        }));
    };


    // Function to handle form submission
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('name', newTeamLeader.name);

            formData.append('collection', newTeamLeader.collection);
            formData.append('confirmation', newTeamLeader.confirmation);
            formData.append('closure', newTeamLeader.closure);
    
            await axios.post('https://scoreboard.codingindia.co.in/CreateTeamLeaderType/', formData);
    
            setSuccessMessage('Data saved Successfully');
            // Clear the form after successful submission
            setNewTeamLeader({
                name: '',
                collection: 0,
                confirmation: 0,
                closure: 0,

            });
        } catch (error) {
            console.error('Error saving Team Leader:', error);
        }
    };

    useEffect(() => {
        // Fetch the marketting types from your Django backend
        fetch('https://scoreboard.codingindia.co.in/teamleader-types/')
            .then(response => response.json())
            .then(data => setMarkettingTypes(data))
            .catch(error => console.error('Error fetching marketting types:', error));
    }, []);


    return (
        <div>
            <div className="messagedi">
                <h1 className="text-center">Add Team Leader</h1>
                {successMessage && (
                    <p style={{ width: '50%', margin: 'auto', padding: '15px', backgroundColor: '#99BC85', color: 'white' }}>
                        {successMessage}
                    </p>
                )}
            </div>

            <div class="container mx-auto px-4" style={{ width: '50%', margin: 'auto', marginTop: '35px' }}>
                <form onSubmit={handleFormSubmit} encType="multipart/form-data">
                    <div class="grid grid-cols-2 gap-3">
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="name">
                                Name
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                value={newTeamLeader.name}
                                onChange={handleInputChange}
                                type="text"
                                id="name"
                                name="name"
                                required
                            />
                        </div>

                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="collection">
                                Collection
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                type="number"
                                value={newTeamLeader.collection}
                                onChange={handleInputChange}
                                id="collection"
                                name="collection"
                                required
                            />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="confirmation">
                                Confirmation
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                type="number"
                                value={newTeamLeader.confirmation}
                                onChange={handleInputChange}
                                id="confirmation"
                                name="confirmation"
                                required
                            />
                        </div>
                        <div class="col-span-2">
                            <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300" for="closure">
                                Closure
                            </label>
                            <input
                                class="block w-full px-4 py-2 text-sm text-gray-700 bg-white border rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                type="number"
                                value={newTeamLeader.closure}
                                onChange={handleInputChange}
                                id="closure"
                                name="closure"
                                required
                            />
                        </div>

                    </div>

                    <div class="flex justify-end mt-4 mb-4">
                        <button
                            type="submit"
                            class="px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none focus:ring"
                        >
                            Create
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateTeamLeaderTarget;
