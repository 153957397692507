// src/components/Navbar.js
import { React, useEffect, useState } from 'react';
import logo from '../assets/images/Pace logo.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const Navbar = (props) => {
  const navigate = useNavigate();
  const additionalProp = props.dataProp;
  const [userPerm, setUserPerm] = useState(null);
  console.log(additionalProp);
  // const { dataProp } = props;
  // console.log(dataProp)

  const handleSignOut = () => {
    // Perform sign-out logic here, for example, clearing the authentication token.
    // After signing out, you can redirect the user to the desired page.
    // In this case, let's redirect them to the home page.

    // For illustration purposes, let's clear the access token from localStorage
    localStorage.removeItem("access_token");

    // Redirect to the home page
    navigate('/');
  };

  useEffect(() => {
    const fetchUserPerm = async () => {
      try {
        const response = await axios.get("https://scoreboard.codingindia.co.in/GetUserPermissions/");
        console.log("Hello", response.data)
        setUserPerm(response.data);

      } catch (error) {
        console.error("Error fetching announcements:", error);
      }
    }

    fetchUserPerm();
  }, []);


  return (


    <nav class="bg-white ">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-1">
        <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} class="h-8" alt="Leaderboard" />
          <span class="self-center text-3xl font-bold whitespace-nowrap dark:text-white" style={{ color: "#0369a1" }}>Leaderboard</span>
        </a>
        <button data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
        </button>
        <div class="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul class="font-medium flex flex-col p-3 md:p-0 mt-1  rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

            <li>
              {/* <a href="#"  >Home</a> */}
              <Link to="/records" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Home</Link>
            </li>
            <li>
              {/* <a href="#" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">About</a> */}
              <Link to="/create" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >Create</Link>
            </li>


            {userPerm === "tlandbh" && (
              <>
                <li>
                  <Link to="/sub_admin_view" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" >All HR</Link>
                </li>
              </>
            )}

            {userPerm !== "tlandbh" && userPerm !== "hr_permission" && (
              <>
                

                <li class="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Team Leader
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/createteamleader',
                        state: { additionalProp }
                      }}>
                        <li>Add Team Leader</li>
                      </Link>
                    </li>
                    
                    <li>
                      <Link class="dropdown-item" to={{
                        pathname: '/showteamleader',
                        state: { additionalProp }
                      }}>
                        <li>View Team Leader</li>
                      </Link>
                    </li>
                   
                  </ul>
                </li>
              </>
            )}
            <a href="/news" class="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">Announcements</a>

            <li>
              <Link
                className="nav-item dropdown block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
                to={{ pathname: '/', state: { additionalProp } }}
                onClick={handleSignOut}
              >
                Sign Out
              </Link>

            </li>

          </ul>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;
