import axios from 'axios';

let refresh = false;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !refresh) {
      refresh = true;
      const refreshToken = localStorage.getItem('refresh_token');
      try {
        // const response = await axios.post(
        //   'https://scoreboard.codingindia.co.in/token/refresh/',
        //   { refresh: refreshToken },
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //     },
        //     withCredentials: true,
        //   }
        // );
        // if (response.status === 200) {
        //   axios.defaults.headers.common[
        //     'Authorization'
        //   ] = `Bearer ${response.data.access}`;
        //   localStorage.setItem('access_token', response.data.access);
        //   localStorage.setItem('refresh_token', response.data.refresh);
        //   refresh = false;
        //   return axios(error.config);
        // }
      } catch (refreshError) {
        // Handle any errors during token refresh if needed
        console.error('Error refreshing token:', refreshError);
      }
    }
    refresh = false;
    return Promise.reject(error);
  }
);