import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import qimg from "../assets/images/qoutes.png";
import Announcementslider from "./Announcementslider";


const quotes = [
  "Every connection you make brings us one step closer to our audience.",
  "Innovation in marketing paves the way for our brand's evolution."
];

const InMarketingTeam = () => {

  // State variables
  const [markettingList, setMarkettingList] = useState([]);
  const [currentMarkettingIndex, setCurrentMarkettingIndex] = useState(0);
  const navigate = useNavigate();
  const [paceColor, setPaceColor] = useState('');
  const [ddE2Estatus, setDDE2EStatus] = useState('');
  const [weeklyData, setWeeklyData] = useState(null);
  const [dailyVisistEntry, setDailyVisistEntry] = useState(null);
  const [todaysDailyVisittarget, settodaysDailyVisittarget] = useState('');


  // const [tdailyVisitval, setTdailyVisitVal] = useState(null);

  // Additional state variable for percentage completion
  const [percentageCompletionPace, setPercentageCompletionPace] = useState(0);
  const [percentageCompletionDDclient, setPercentageCompletionDDclient] = useState(0);
  const [percentageCompletiontdailyvisit, setPercentageCompletiontdailyvisit] = useState(0);
  const [percentageCompletiontE2E, setPercentageCompletiontE2E] = useState(0);
  const [percentageCompletionttpl, setPercentageCompletionttpl] = useState(0);
  const [percentageCompletiondid, setPercentageCompletiondid] = useState(0);


  // Fetch announcements on component mount


  // Fetch marketing types on component mount
  const fetchMarkettingList = async () => {
    try {
      const response = await axios.get("https://scoreboard.codingindia.co.in/marketting/all/");
      // // console.log("Error: ", response.data.records)
      setMarkettingList(response.data.records);
      // setWeeklyData(response);

    } catch (error) {
      // console.error("Error fetching marketting values:", error);
    }
  };

  // Fetch marketing values on component mount
  useEffect(() => {
    fetchMarkettingList();
  }, []);

  const getTotalDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
  };

  // Fetch marketing types periodically and update currentMarkettingIndex
  useEffect(() => {
    const fetchMarkettingTypes = async () => {
      try {
        // // console.log("Test: ", markettingList[currentMarkettingIndex]?.username)
        if (markettingList[currentMarkettingIndex]?.username) {
          const response = await axios.get(`https://scoreboard.codingindia.co.in/GetPerUserMarkettingDetailsslider/${markettingList[currentMarkettingIndex]?.username}/`);
          // console.log("Response Data: ", response.data)
          // console.log("Response Datass: ", response.data.daily_visit_entry)

          let dailyVisitValue;
          var totalDailyVisit = 0;

          try {
            const dailyVisitEntry = JSON.parse(response.data.daily_visit_entry);
            totalDailyVisit = dailyVisitEntry[0].fields.dailyVisit;

            // console.log("Hello", totalDailyVisit)
            setDailyVisistEntry(totalDailyVisit)
          } catch (error) {
            // console.error("Error parsing JSON:", error);
            // Handle the error appropriately, e.g., setting totalDailyVisit to 0
            totalDailyVisit = 0;
            setDailyVisistEntry(totalDailyVisit)
          }
          // Now you have the total_dailyVisit value accessible in totalDailyVisit variable
          // console.log("Total Daily Visit:", totalDailyVisit);

          setWeeklyData(response.data.monthly_records);

          const assignedVal = response.data.records
          const assignedtpl = assignedVal.total_prefered_location;
          const assignedDDclient = assignedVal.DDclient;
          const assigneddailyVisit = assignedVal.dailyVisit;
          // // console.log("Daily Visit: ", assigneddailyVisit)

          const assignedddE2E = assignedVal.ddE2E;
          const assignedpace = assignedVal.pace;
          const assigneddid = assignedVal.docladerId;

          // // console.log("Assigned Data: ", assignedtpl, assignedDDclient, assigneddailyVisit, assignedddE2E, assignedpace)

          const targetobj = JSON.parse(response.data.target)
          const targetVal = targetobj[0].fields;
          // // console.log("TargetVal: ", targetVal)

          const ttpl = targetVal.total_prefered_location;
          const tDDclient = targetVal.DDclient;
          const tdailyVisit = targetVal.dailyVisit;
          // console.log("Daily Visit: ", tdailyVisit)
          // // console.log("Monthly Daily Visit: ", tdailyVisit)
          // const targetdV = tdailyVisit / ;
          const tddE2E = targetVal.ddE2E;
          setDDE2EStatus(tddE2E)
          // // console.log("E2E: ", tddE2E)
          const tpace = targetVal.pace;
          const tdid = targetVal.docladerId;

          // // console.log("Daily Visit Entry: ", tddE2E)

          const today = new Date();
          const year = today.getFullYear();
          const month = today.getMonth() + 1; // Adding 1 to get the current month (as month is zero-based)

          const totalDays = getTotalDaysInMonth(year, month);
          // console.log("Total Days: ", totalDays)
          const todaysDailyVisit = Math.floor(tdailyVisit / totalDays)
          // console.log("Todays Daily Visist : ", todaysDailyVisit)
          settodaysDailyVisittarget(todaysDailyVisit)

          // console.log("Daily Visist value: ", (assigneddailyVisit/todaysDailyVisit)*100)
          const percentageCompletionPace = (assignedpace / tpace) * 100;
          const percentageCompletionDDclient = (assignedDDclient / tDDclient) * 100;
            const percentageCompletiontdailyvisit = ((totalDailyVisit / todaysDailyVisit) * 100);
            // console.log("Percentage Daily Visit: ", percentageCompletiontdailyvisit)

          // console.log("Divide: ", totalDailyVisit, todaysDailyVisit, percentageCompletiontdailyvisit)
          const percentageCompletiontE2E = (assignedddE2E / tddE2E) * 100;
          const percentageCompletionttpl = (assignedtpl / ttpl) * 100;
          const percentageCompletiondid = (assigneddid / tdid) * 100;

          setPercentageCompletionDDclient(percentageCompletionDDclient)
          setPercentageCompletionPace(percentageCompletionPace)
          setPercentageCompletiontE2E(percentageCompletiontE2E)
          setPercentageCompletiontdailyvisit(percentageCompletiontdailyvisit)
          setPercentageCompletionttpl(percentageCompletionttpl)
          setPercentageCompletiondid(percentageCompletiondid)

          // // console.log(`Daily Visit: ${percentageCompletiontdailyvisit}% completed`);

          // Use response.data directly here

          const getColorBasedOnPercentage = (percentage) => {
            if (percentage < 50) {
              return "#FF5757"; //  rose red
            } else if (percentage >= 50 && percentage < 70) {
              return "#FFDE59"; // yellow
            } else if (percentage >= 70 && percentage < 90) {
              return "#FFBD59"; // orange
            } else if (percentage <= 100) {
              return "#7ED957"; // green
            }
            else {
              return "#0284c7"; // Blue For Excellent
            }
          };

          // console.log("Latest News: ", response.data.latest_news)
          if (response.data.latest_news == "New News Updated") {
            navigate("/celebrationPage")
          }
        }
      } catch (error) {
        // console.error('Error fetching marketting types:', error);
      }
    };

    const intervalId = setInterval(() => {
      setCurrentMarkettingIndex((prevIndex) => (prevIndex + 1) % markettingList.length);

      if (currentMarkettingIndex === markettingList.length - 1) {
        clearInterval(intervalId); // Stop the interval
        navigate('/business'); // Redirect to /records
      }
    }, 5000);

    fetchMarkettingTypes();

    return () => clearInterval(intervalId);
  }, [currentMarkettingIndex, markettingList]);

  const getColorBasedOnPercentage = (percentage) => {
    if (percentage < 25) {
      return '#FF5757';
    } else if (percentage < 50) {
      return '#FFDE59';
    } else if (percentage < 75) {
      return '#FFBD59';
    } else {
      return '#7ED957';
    }
  };

  // Current marketing type
  const currentMarketting = markettingList[currentMarkettingIndex];

  // // console.log("DDE2E Status: ",  typeof(tdailyVisit))

  // JSX rendering
  return (
    <div className="businessHead text-center container">
      <Announcementslider />
      

      <div class="row">
        <div class="col-12">
          <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-7">
                    <div class="row">
                      <div
                        class="col-8"
                        style={{ marginTop: "30px", textAlign: "left" }}
                      >
                        <h1 class="authorName">{currentMarketting?.name}</h1>
                        <p class="authorPos">{currentMarketting?.mtype}</p>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}></div>
                    </div>
                    <div class="row">
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="resumeForward" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletionttpl) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.total_prefered_location}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h3>
                                        {dailyEntry.total_prefered_location}
                                      </h3>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Monthly</span>
                          </div>
                          <p>Prefered Location</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="turnedUp" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletionPace) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.pace}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h3>
                                        {dailyEntry.pace}
                                      </h3>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>Lac / Monthly</span>
                          </div>
                          <p>PACE Lead</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="selectionCand" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletiondid) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.docladerId}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h3>
                                        {dailyEntry.docladerId}
                                      </h3>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Monthly</span>
                          </div>
                          <p>Docladder ID</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="resumeForward" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletionDDclient) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.DDclient}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h3>
                                        {dailyEntry.DDclient}
                                      </h3>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Monthly</span>
                          </div>
                          <p>DD Client</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="turnedUp" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletiontdailyvisit) }}>
                          <div className="weekMonth">

                            <h3>{dailyVisistEntry ? dailyVisistEntry : 0}</h3>
                            <span>/ {todaysDailyVisittarget}</span>
                          </div>
                          <p>Daily Visit</p>
                        </div>
                      </div>
                      {ddE2Estatus !== 0 && ddE2Estatus !== null ? (
                        <div class="col-4" style={{ marginTop: "30px" }}>
                          <div class="turnedUp" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletiontE2E) }}>
                            <div className="weekMonth">
                              {/* <h3>{currentMarketting?.ddE2E}</h3> */}
                              {weeklyData &&
                                weeklyData.map((dailyEntry) => {
                                  if (dailyEntry.user === currentMarketting?.id) {
                                    return (
                                      <React.Fragment key={dailyEntry.id}>
                                        <h3>
                                          {dailyEntry.ddE2E}
                                        </h3>
                                      </React.Fragment>
                                    );
                                  }
                                  return null;
                                })}
                              <span>/ Monthly</span>
                            </div>
                            <p>DD E2E</p>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  </div>
                  <div class="col-5 userImageDiv" style={{ marginTop: "30px" }}>
                    <img src={`https://scoreboard.codingindia.co.in${currentMarketting?.image}`} alt="" class="userImage" />
                  </div>
                </div>
                <div class="row">
                  <div className="col-12" style={{ marginTop: "30px" }}>
                    <div class="qoutesBoxx">
                      <img src={qimg} />
                      <q className="text-3xl">{quotes[currentMarkettingIndex]}</q>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InMarketingTeam;
