import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Navbar from "../NavBar";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// start for multi select mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


// end for multi select mui



function UserWiseTeamLeader() {


    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const location = useLocation();
    const stateValue = location.state && location.state['newUser'];
    const [data, setData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tasks, setTasks] = useState(null);
    const [targets, setTargets] = useState(null);
    const [inputValues, setInputValues] = useState({ value: '' });
    const [selectedtaskid, setSelectedTaskId] = useState({ value: '' });
    const [targetDays, setTargetDays] = useState('');
    const [selectedTaskName, setSelectedTaskName] = useState('');
    const [ename, setEname] = useState('');
    const [dateJoined, setDateJoined] = useState('');
    const [dateHandovered, setDateHandovered] = useState('');
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [topic, setTopic] = useState([]);
    const [old_emp_training_topic, setold_emp_training_topic] = useState([]);

    const [tdate, setTdate] = useState([]);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleSignOut = () => {
        localStorage.removeItem("access_token");
        navigate('/');
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('access_token');
                console.log(token)
                if (token) {
                    const response = await axios.get(
                        'https://scoreboard.codingindia.co.in/LeadersTable/GetLeaderDatafromUsername/',
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        }
                    );

                    const allusers = await axios.get('https://scoreboard.codingindia.co.in/get-all-users/',
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                            },
                        }
                    )
                    console.log("All Users: ", allusers.data)
                    setUsers(allusers.data)

                    console.log("Data: ", response.data)

                    setData(response.data.leader);
                    setTasks(response.data.leader.currentVal);
                    setTargets(response.data.leader.tasks);
                    setEname(response.data.leader.ename); // Set ename
                    setDateJoined(response.data.leader.date_joined); // Set date_joined
                    setDateHandovered(response.data.leader.date_handovered); // Set date_handovered
                } else {
                    throw new Error('Access token not found');
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            }
        };

        fetchData();
    }, []);

    const openModal = (task) => {
        setSelectedTask(task);
        setSelectedTaskName(task.name); // Store the selected task name
        setIsModalOpen(true);
        if (task.name === "Induction") {
            setTargetDays('1');
        } else if (task.name === "Smooth handover Process") {
            setTargetDays('3');
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTask(null); // Reset selected task
        setInputValues({ value: '' }); // Reset input values
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     try {
    //         const response = await axios.post(
    //             'https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/',
    //             {
    //                 name: selectedTask.id,
    //                 value: inputValues.value,
    //                 targetdays: targetDays, // Include targetdays field
    //                 ename, // Include ename field
    //                 date_joined: dateJoined, // Include date_joined field
    //                 date_handovered: dateHandovered, // Include date_handovered field
    //             }
    //         );
    //         if (response.status === 200) {
    //             console.log('Task saved successfully');
    //             closeModal(); // Close the modal after successful submission
    //         } else {
    //             throw new Error('Failed to save current month task');
    //         }
    //     } catch (error) {
    //         console.error('Error ', error);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let postData = {
                name: selectedTask.id,
                value: inputValues.value,
                targetdays: targetDays,
            };

            if (selectedTask.name === "Induction") {
                postData = {
                    ...postData,
                    ename,
                    date_joined: dateJoined,
                    date_handovered: dateHandovered,
                };
                // Post data to Induction endpoint
                const response = await axios.post(
                    'https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/',
                    postData
                );

                if (response.status === 200) {
                    console.log('Induction task saved successfully');
                    closeModal();
                } else {
                    throw new Error('Failed to save Induction task');
                }
            } else if (selectedTask.name === "New Employee Training") {
                postData = {
                    ...postData,
                    topic,
                    users: selectedUsers,
                    task: selectedTask.id,

                    tdate: tdate,
                };
                // Post data to New Employee Training endpoint
                const response = await axios.post(
                    'https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/',
                    postData
                );

                if (response.status === 200) {
                    console.log('New Employee Training saved successfully');
                    closeModal();
                } else {
                    throw new Error('Failed to save New Employee Training');
                }
            }
            else if (selectedTask.name === "Old Emplyees Training") {
                postData = {
                    ...postData,
                    old_emp_training_topic,
                    users: selectedUsers,
                    oldtask: selectedTask.id,
                    old_t_date: tdate,
                };
                // Post data to New Employee Training endpoint
                const response = await axios.post(
                    'https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/',
                    postData
                );

                if (response.status === 200) {
                    console.log('New Employee Training saved successfully');
                    closeModal();
                } else {
                    throw new Error('Failed to save New Employee Training');
                }
            }
            else {
                const response = await axios.post(
                    'https://scoreboard.codingindia.co.in/LeadersTable/CreateTargetsOfTasks/',
                    postData
                );

                if (response.status === 200) {
                    console.log('Task saved successfully');
                    closeModal();
                } else {
                    throw new Error('Failed to save task');
                }
            }
        } catch (error) {
            console.error('Error ', error);
        }
    };


    const handleUserSelect = (e) => {
        setSelectedUsers(Array.from(e.target.selectedOptions, option => option.value));
    };


    return (
        <>
            <section>
                <div className="container-fluid leaderViewContainer">
                    {data && (
                        <div className="leaderdetailcontainer">
                            <div className="row">
                                <div className="col-3">
                                    <img className="w-auto align-horizontal" style={{ height: "100px" }} src={`https://scoreboard.codingindia.co.in${data.image}`} alt="" />
                                </div>
                                <div className="col-4">
                                    <div className="userViewText">
                                        <h3>{data.name}</h3>
                                        <span>{data.dept} <Link className="signOutLink" to={{ pathname: '/' }} onClick={handleSignOut}><i class="fa-solid fa-right-from-bracket"></i></Link></span>
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="userViewProgress">
                                        <div className="userViewProgText"></div>
                                        <div className="userViewProgessBar">
                                            <p className="mx-1 my-1 text-center">
                                                <div className={`progress-bar userViewProgDiv ${data.currentpercentage < 50 ? "userViewProgRed" : data.currentpercentage < 80 ? "userViewProgYellow" : "userViewProgGreen"}`} style={{ width: `${data.currentpercentage}%` }}>
                                                    {data.currentpercentage === 50 ? <span style={{ marginLeft: "-15px" }}>{data.currentpercentage}%</span> : data.currentpercentage + "%"}
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <table className="myTable min-w-full bg-white" style={{ width: "95%", margin: "auto" }}>
                        <thead>
                            <tr>
                                <th scope="col">Task Name</th>
                                <th scope="col" className="imageWidth">Target</th>
                                <th scope="col" className="nameWidth">Current Value</th>
                                <th scope="col" className="percentageWidth">Point</th>
                                <th scope="col" className="percentageWidth">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {targets ? (
                                <>
                                    {targets && targets.map((target, index) => (
                                        <tr key={index}>
                                            <td className="py-2 px-4 border-b text-center">{target.name}</td>
                                            <td className="py-2 px-4 border-b text-center">{target.target}</td>
                                            {tasks && tasks.map((task) => {
                                                if (target.id === task.name) {
                                                    return (
                                                        <React.Fragment key={task.name}>
                                                            <td className="py-2 px-4 border-b text-center">{task.total_taskinput}</td>
                                                            <td className="py-2 px-4 border-b text-center">{task.total_points}</td>
                                                        </React.Fragment>
                                                    );
                                                }
                                                return null;
                                            })}
                                            <td className="py-2 px-4 border-b text-center">
                                                <button className='leaderTaskBtn' onClick={() => openModal(target)}><i className="fa-solid fa-check"></i> Complete</button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="3">Loading...</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {isModalOpen && (
                        <div className="modal leaderTaskModel">
                            <div className="modal-content">
                                <h2 >Complete Task</h2>
                                <form onSubmit={handleSubmit}>
                                    <label className="form-label">Task Name:</label>
                                    <input
                                        type="text"
                                        value={selectedTask ? selectedTask.name : ''}
                                        readOnly
                                    />



                                    <label>Value:</label>
                                    <input
                                        type="text"
                                        value={inputValues.value}
                                        onChange={(e) => setInputValues({ value: e.target.value })}
                                    />
                                    {selectedTask.name === "Induction" && (
                                        <>
                                            <label>Name:</label>
                                            {/* <input type="text" value={ename} onChange={(e) => setInputValues({ ...inputValues, ename: e.target.value })} id='ename' /> */}
                                            <input type="text" value={ename} onChange={(e) => setEname(e.target.value)} id='ename' />


                                            <label>target Days:</label>
                                            {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
                                            <input type="text" value={targetDays} readOnly onChange={(e) => setTargetDays(e.target.value)} id='targetdays' />


                                            <label>Date Joined:</label>
                                            {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
                                            <input type="text" value={dateJoined} onChange={(e) => setDateJoined(e.target.value)} id='dateJoined' />


                                            <label>Date Of Handover:</label>
                                            {/* <input type="text" value={dateHandovered} onChange={(e) => setInputValues({ ...inputValues, dateHandovered: e.target.value })} id='dateHandovered' /> */}
                                            <input type="text" value={dateHandovered} onChange={(e) => setDateHandovered(e.target.value)} id='dateHandovered' />

                                        </>
                                    )}
                                    {selectedTask.name === "New Employee Training" && (
                                        <>
                                            <label>Topic Of Training</label>
                                            {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
                                            <input type="text" value={topic} onChange={(e) => setTopic(e.target.value)} id='topic' />


                                            <select multiple id="users" value={selectedUsers} onChange={handleUserSelect}>
                                                {users.map(user => (
                                                    <option key={user.id} value={user.id}>{user.username}</option>
                                                ))}
                                            </select>


                                            <label>Date Of Training:</label>
                                            {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
                                            <input type="date" value={tdate} onChange={(e) => setTdate(e.target.value)} id='dateJoined' />

                                        </>
                                    )}
                                    {selectedTask.name === "Old Emplyees Training" && (
                                        <>


                                            <label>Topic Of Training</label>


                                            {/* <FormControl sx={{ m: 1, width: 300 }}>
                                                <InputLabel id="demo-multiple-chip-label">Chip</InputLabel>
                                                <Select
                                                    labelId="demo-multiple-chip-label"
                                                    id="demo-multiple-chip"
                                                    multiple
                                                    value={personName}
                                                    onChange={handleChange}
                                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                    renderValue={(selected) => (
                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                            {selected.map((value) => (
                                                                <Chip key={value} label={value} />
                                                            ))}
                                                        </Box>
                                                    )}
                                                    MenuProps={MenuProps}
                                                >
                                                    {names.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, personName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl> */}
                                            {/* <input type="text" value={targetDays} readOnly onChange={(e) => setInputValues({ ...inputValues, targetDays: e.target.value })} id='targetdays' /> */}
                                            <input type="text" value={old_emp_training_topic} onChange={(e) => setold_emp_training_topic(e.target.value)} id='old_emp_training_topic' />


                                            <select multiple id="users" value={selectedUsers} onChange={handleUserSelect}>
                                                {users.map(user => (
                                                    <option key={user.id} value={user.id}>{user.username}</option>
                                                ))}
                                            </select>


                                            <label>Date Of Training:</label>
                                            {/* <input type="text" value={dateJoined} onChange={(e) => setInputValues({ ...inputValues, dateJoined: e.target.value })} id='dateJoined' /> */}
                                            <input type="date" value={tdate} onChange={(e) => setTdate(e.target.value)} id='dateJoined' />

                                        </>
                                    )}
                                    <button type="submit" className='saveBtn'>Save</button>
                                    <button type="button" className='cancelBtn' onClick={closeModal}>Cancel</button>
                                </form>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}

export default UserWiseTeamLeader;
