import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Confetti from "react-confetti";
import "./CelebrationPage.css";
import welcomeBg from '../assets/images/welcome.jpg';
import myvidbg from '../assets/Videos/conf1.mp4';
import axios from 'axios';
import qimg from "../assets/images/qoutes.png";
import vid from "../assets/Videos/firework.mp4";



const CelebrationPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [focusedIndex, setFocusedIndex] = useState(0);

  const motivationalQuotes = [
    "Alone we can do so little; together we can do so much. - Helen Keller",
    "Coming together is a beginning; keeping together is progress; working together is success. - Henry Ford",
    "Individually, we are one drop. Together, we are an ocean. - Ryunosuke Satoro",
    "The strength of the team is each individual member. The strength of each member is the team. - Phil Jackson",
    "Great things in business are never done by one person; they're done by a team of people. - Steve Jobs"
  ];

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      fetch("https://scoreboard.codingindia.co.in/api/all_latest_news/", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setData(data || []);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (data && data.length > 0) {
        setFocusedIndex(prevIndex => (prevIndex + 1) % data.length);

        if (focusedIndex === data.length - 1) {
          navigate('/records');
        }
      } else {
        navigate('/records');

      }
    }, 12000);

    return () => clearInterval(interval);
  }, [data, focusedIndex, navigate]);

  useEffect(() => {
    const fetchRecordData = async () => {
      try {
        if (data && data.length > 0) {
          const currentRecord = data[focusedIndex];
          const response = await axios.get(`https://scoreboard.codingindia.co.in/api/latest_news/${currentRecord.id}/`);
          setUserData(response.data.foruser)
        }
      } catch (error) {
        console.error('Error fetching record data:', error);
      }
    };

    fetchRecordData();
  }, [focusedIndex, data]);

  const randomQuote = motivationalQuotes[Math.floor(Math.random() * motivationalQuotes.length)];

  // Render the component only if data is not null
  if (data === null) {
    return null; // or you can render a loading indicator
  }
  return (
    <>
      <div id="welcomeaboard">
        {data && data.length > 0 && focusedIndex < data.length && data[focusedIndex] &&
          data[focusedIndex].name === "new user created" ? (
          // ******************* new user created **************
          <div className='celebrationPage'>
            <Confetti />

            {userData && userData.image && (
              <div className='welcomecontainer'>
                <img src={`https://scoreboard.codingindia.co.in${userData.image}`} id='celebimg' alt="" />
                <div className="celebrationText">
                  <h1 className='celebHead text-white'>WELCOME ABOARD</h1>
                  <h1 className='celebHead heartbeat-text'>{userData.name}</h1>
                  <h6 className='celebU text-xl text-white'>You are now</h6>

                  {data[focusedIndex].dept === "Docladder" ? (
                    <>
                      <h5 className='celebHash text-2xl' style={{ color: "gold", fontWeight: "800" }}>#TeamDocladder</h5>
                    </>
                  ) : data[focusedIndex].dept === "Docladder Digital" ? (
                    <h5 className='celebHash text-2xl' style={{ color: "gold", fontWeight: "800" }}>#TeamDocladderDigital</h5>
                  ) : data[focusedIndex].dept === "PACE" ? (
                    <h5 className='celebHash text-2xl' style={{ color: "gold", fontWeight: "800" }}>#TeamPace</h5>
                  ) : (
                    <>
                      <h5 className='celebHash text-2xl' style={{ color: "gold", fontWeight: "800" }}>#TeamPace</h5>
                    </>
                  )}

                  <p className='celebMsg text-2xl text-white'>Your skills and talent will be a great addition to our organization.</p>
                </div>
              </div>
            )}
          </div>
        ) : data && data.length > 0 && focusedIndex < data.length && data[focusedIndex] &&
          data[focusedIndex].name === "Your CV Forwarded" ? (
          // ******************* Your CV Forwarded **************

          <div className='container celebrationPage2'>
            <Confetti />

            {userData && userData.image && (
              <div className='container welcomecontainer'>
                <img src={`https://scoreboard.codingindia.co.in${userData.image}`} id='celebimg' alt="" />
                <div className="celebrationText">
                  <h1 className='celebHead text-blue'>Congratulations</h1>
                  <h1 className='celebHead heartbeat-text1'>{userData.name}</h1>

                  <h6 className='celebU text-3xl text-blue'>Brace yourself for <span style={{ color: '#d97706', fontWeight: "800" }}>brilliance</span>. Resume forwarded!</h6>

                </div>
              </div>
            )}
          </div>
          // ******************* end Your CV Forwarded **************

        ) : data && data.length > 0 && focusedIndex < data.length && data[focusedIndex] &&
          data[focusedIndex].name === "Your CV Selected" ? (
          // ******************* Your CV Selected **************
          <>
            {userData && userData.image && (
              <>
                <div className="relative">
                  <video src={vid} autoPlay loop muted className='fireWorkVid absolute object-cover'></video>

                  {/* <div class="firework absolute inset-0" >
                    <div class="fire" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}></div>
                    <div class="blast" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}></div>
                  </div> */}
                  <div class="celebpagecontainer" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h1 style={{ color: "#DAA520" }}>Congratulations</h1>
                        <h2 style={{ color: "white" }}>Your Resume Has Been Selected</h2>

                        <div class="row">
                          <div className="col-12" style={{ marginTop: "9rem" }}>
                            <div class="qoutesBoxx">
                              <img src={qimg} />
                              <q className="text-3xl">Nothing is impossible <br /> The word itself say's I'm Possible</q>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="" style={{ marginLeft: "12rem" }}>
                        <img src={`https://scoreboard.codingindia.co.in${userData.image}`} id='celebimg' alt="" />
                        <div class="box" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden", marginLeft: "25px" }}>
                          <div class="title" style={{ textAlign: "center" }}>
                            <span class="block" style={{ maxWidth: "100%", maxHeight: "100vh", overflow: "hidden" }}></span>
                            <h1 class="text-white">{userData.name}<span></span></h1>
                          </div>

                          <div class="role">
                            <div class="block text-center"></div>
                            <p style={{ textAlign: "center", fontSize: "1.2rem", fontWeight: "600" }}>HR Associate</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

            )}

          </>
          // ******************* end Your CV Selected **************
        ) : (
          <>
            <div className=" min-h-screen justify-center"  style={{ height: "100vh" ,backgroundImage: `url('https://images.unsplash.com/photo-1513538318453-d0cc70acc86d?&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`, backgroundSize: "100% 100vh" }}>
              <div className=" mx-auto text-center p-8 rounded-lg shadow-lg" style={{  }}>
                <h1 className="text-6xl font-bold mb-4 " style={{ color: "white", lineHeight: "1.5em", wordSpacing: "16px" }}>{randomQuote}</h1>

              {/*   <h1 className="text-4xl font-bold mb-4">Welcome to Leaderboard</h1>
                <p className="text-xl mb-8">Start tracking your progress now!</p>
                <div className="border-l-4 border-purple-400 pl-4">
                </div>  */}
              </div>
            </div>
          </>
        )}

      </div >
    </>
  );
};

export default CelebrationPage;
