import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import qimg from "../assets/images/qoutes.png";
import Announcementslider from "./Announcementslider";


const BusinessHead = () => {
  // State variables
  const [announcements, setAnnouncements] = useState([]);
  const [markettingList, setMarkettingList] = useState([]);
  const [currentMarkettingIndex, setCurrentMarkettingIndex] = useState(0);
  const [weeklyData, setWeeklyData] = useState(null);
  const [latest_news, setLatestNews] = useState([]);

  const navigate = useNavigate();

  // Additional state variable for percentage completion
  const [percentageCompletionCollection, setPercentageCompletionCollection] = useState(0);
  const [percentageCompletionConfirmation, setPercentageCompletionConfirmation] = useState(0);
  const [percentageCompletiontClosure, setPercentageCompletiontClosure] = useState(0);



  // Fetch marketing types on component mount
  const fetchMarkettingList = async () => {
    try {
      const response = await axios.get("https://scoreboard.codingindia.co.in/teamleader/");
      console.log("Team Leader Records: ", response.data.records)
      setMarkettingList(response.data.records);
      setWeeklyData(response.data.weekly_records);

    } catch (error) {
      console.error("Error fetching marketting values:", error);
    }
  };

  // Fetch marketing values on component mount
  useEffect(() => {
    fetchMarkettingList();
  }, []);

  useEffect(() => {
    const fetchMarkettingTypes = async () => {
      try {
        console.log("Test: ", markettingList[currentMarkettingIndex]?.username);
        if (markettingList[currentMarkettingIndex]?.username) {
          const response = await axios.get(`https://scoreboard.codingindia.co.in/GetPerUserTeamLeaderDetailsslider/${markettingList[currentMarkettingIndex]?.username}/`);
          console.log("Response Data: ", response, response.data.records.collection);
          setLatestNews(response.data.latest_news);
          console.log("Response Data Latsest News: ", latest_news)
  
          const assignedCollection = response.data.records.collection;
          const assignedddConfirmation = response.data.records.confirmation;
          const assignedclosure = response.data.records.closure;
  
          const targetobj = JSON.parse(response.data.target);
          const targetVal = targetobj[0].fields;
  
          const tCollection = targetVal.collection;
          const tConfirmation = targetVal.confirmation;
          const tClosure = targetVal.closure;
  
          const percentageCompletionCollection = (assignedCollection / tCollection) * 100;
          const percentageCompletionConfirmation = (assignedddConfirmation / tConfirmation) * 100;
          const percentageCompletiontClosure = (assignedclosure / tClosure) * 100;
  
          setPercentageCompletionCollection(percentageCompletionCollection);
          setPercentageCompletionConfirmation(percentageCompletiontClosure);
          setPercentageCompletiontClosure(percentageCompletiontClosure);
        }
      } catch (error) {
        console.error('Error fetching marketting types:', error);
      }
    };
    fetchMarkettingTypes();

  }, [currentMarkettingIndex, markettingList]);
  

  useEffect (()=>{
    const intervalId = setInterval(() => {
      console.log("l2 news: ", latest_news)
      setCurrentMarkettingIndex((prevIndex) => (prevIndex + 1) % markettingList.length);
      if (currentMarkettingIndex === markettingList.length - 1) {
        clearInterval(intervalId); // Stop the interval
        if (latest_news.length >= 1) {
          navigate('/celebrationPage'); // Redirect to /records
        } else {
          navigate("/records");
          console.log("Navigating")
        }
      }
    }, 5000);
  
  
    return () => clearInterval(intervalId);
  })

  const getColorBasedOnPercentage = (percentage) => {
    if (percentage < 50) {
      return '#FF5757';
    } else if (percentage < 70) {
      return '#FFDE59';
    } else if (percentage < 90) {
      return '#FFBD59';
    } else {
      return '#7ED957';
    }
  };


  // Current marketing type
  const currentMarketting = markettingList[currentMarkettingIndex];

  console.log("Lastest News: ", latest_news)

  // JSX rendering
  return (
    <div className="businessHead text-center container">
      {/* Render marketing details */}

      {/* Render recent events slider */}
      <Announcementslider />


      <div class="row">
        <div class="col-12">
          <div id="carouselExample" class="carousel slide">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                  <div class="col-7">
                    <div class="row">
                      <div
                        class="col-8"
                        style={{ marginTop: "30px", textAlign: "left" }}
                      >
                        <h1 class="authorName">{currentMarketting?.name}</h1>
                        <p class="authorPos">{currentMarketting?.tltype}</p>

                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}></div>
                    </div>
                    <div class="row">
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="resumeForward" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletionCollection) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.collection}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h2>
                                        {dailyEntry.collection}
                                      </h2>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Weekly</span>
                          </div>
                          <p>Collection</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="turnedUp" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletionConfirmation) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.confirmation}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h2>
                                        {dailyEntry.confirmation}
                                      </h2>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Weekly</span>
                          </div>
                          <p>Confirmation</p>
                        </div>
                      </div>
                      <div class="col-4" style={{ marginTop: "30px" }}>
                        <div class="selectionCand" style={{ backgroundColor: getColorBasedOnPercentage(percentageCompletiontClosure) }}>
                          <div className="weekMonth">
                            {/* <h3>{currentMarketting?.closure}</h3> */}
                            {weeklyData &&
                              weeklyData.map((dailyEntry) => {
                                if (dailyEntry.user === currentMarketting?.id) {
                                  return (
                                    <React.Fragment key={dailyEntry.id}>
                                      <h2>
                                        {dailyEntry.closure}
                                      </h2>
                                      {/* Add other daily entry fields as needed */}
                                    </React.Fragment>
                                  );
                                }
                                return null;
                              })}
                            <span>/ Weekly</span>
                          </div>
                          <p>Closure</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-5 userImageDiv" style={{ marginTop: "30px" }}>
                    {/* {currentMarketting?.image} */}
                    <img src={`https://scoreboard.codingindia.co.in${currentMarketting?.image}`} alt="" class="userImage" />
                  </div>

                  {/* <div class="col-7">
                   <div class="busnessHeadQoute">
                   {currentMarketting?.tltype == "Business Head" && (
                          <p>Inspire your team to achieve greatness through leadership and unity.</p>
                        ) }
                   </div>
                   </div> */}
                  <div className="col-12" style={{ marginTop: "30px" }}>
                    <div class="qoutesBoxx">
                      <img src={qimg} />
                      <q className="text-3xl">Inspire your team to achieve greatness through leadership and unity.</q>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessHead;
